@media screen and (max-width: 1024px) {
  .title {
    top: 30%;
  }
}

@media screen and (max-width: 768px) {
  .wrapper-card {
    grid-template-columns: repeat(3, 1fr);
  }

  .tagline {
    top: 15%;
    padding-left: 40px;
    font-size: 50px;
  }

  .wrapper-resto {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .skip-link {
    margin-top: 5px;
  }

  header.nav {
    height: 65px;
  }

  .bg-banner {
    height: 30%;
    object-fit: cover;
  }

  .dropdown-menu.open {
    display: block;
    height: 22rem;
  }

  .list li {
    display: none;
  }

  .toggle-btn {
    display: block;
    height: 100%;
  }

  .title {
    top: 30%;
  }
}

@media (max-width: 480px) {
  .wrapper-card {
    grid-template-columns: repeat(2, 1fr);
  }

  .tagline {
    font-size: 25px;
    padding-left: 20px;
  }

  .wrapper-resto {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .wrapper-gallery img {
    width: 250px;
  }

  .skip-link {
    top: -80px;
    margin-top: 10px;
  }

  .logo {
    font-size: 20px;
  }

  .dropdown-menu.open {
    width: 70%;
  }

  header.nav {
    height: 60px;
  }

  h1,
  h2,
  h4 {
    font-size: small;
  }

  p {
    font-size: small;
  }

  .bg-banner {
    margin-top: 55px;
    object-fit: cover;
  }

  .dropdown-menu.open {
    display: block;
  }

  .list li {
    display: none;
  }

  .footer h5 {
    font-size: 10px;
  }

  .title {
    top: 15%;
  }
}

@media (max-width: 320px) {
  .wrapper-card {
    grid-template-columns: repeat(1, 1fr);
  }

  h1,
  h2,
  h4 {
    font-size: small;
  }

  p {
    font-size: small;
  }

  .bg-banner {
    height: 30%;
    object-fit: cover;
  }

  .dropdown-menu.open {
    display: block;
  }

  .list li {
    display: none;
  }

  footer .footer {
    height: 80px;
  }

  .footer h5 {
    font-size: 10px;
  }
}
